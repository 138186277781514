//Default
$default_background: none;
$default_background-hover: none;
$default_onBackground: none;
$default_stroke: none;

//Primary
$primary_onBackground: #ffffff;
$primary_background: none;
$primary_background-hover: none;
$primary_text: none;
$primary_stroke: none;
$primary_stroke-active: none;
$primary: none;

//Secondary
$secondary_background: none;
$secondary_background-hover: none;
$secondary_onBackground: none;

//Success
$success_background: none;
$success_onBackground: none;
$success_text: none;
$success_shape: none;

//Error
$error_background: none;
$error_onBackground: none;
$error_text: none;
$error_shape: none;

//Elevation
$elevation_default: none;
$elevation_primary: none;
$elevation_onBackground: none;

[contenteditable] {
  outline: 0px solid transparent;
}

// Core colours ----------------------

$borderDark: none;
$borderLight: none;

$neutralHover: none;
$neutralDefault: none;
$neutralFocused: none;
$disabledDefault: none;

$textPrimary: none;
$textSecondary: none;
$textLink: none;
$textDisabled: none;

$Grey-500: none;
$Grey-400: none;
$Grey-300: none;
$Grey-200: none;
$Grey-50: none;

$neutral: (
  Grey-900: #111827,
  Grey-800: #1f2937,
  Grey-700: #374151,
  Grey-600: #4b5563,
  Grey-500: #6b7280,
  Grey-400: #9ca3af,
  Grey-300: #d1d5db,
  Grey-200: #e5e7eb,
  Grey-100: #f3f4f6,
  Grey-50: #fcfcfc,
  Grey-0: #fff,
);

@each $color-key, $color-var in $neutral {
  .neutral--#{$color-key} {
    background-color: $color-var;
  }

  // OLD Default variables. Will be depricated;
  @if $color-key == Grey-0 {
    $default_background: $color-var;
  }
  @if $color-key == Grey-50 {
    $Grey-50: $color-var;
  }
  @if $color-key == Grey-100 {
    $default_background-hover: $color-var;
    $elevation_default: $color-var;
  }
  @if $color-key == Grey-200 {
    $Grey-200: $color-var;
    $elevation_primary: $color-var;
  }
  @if $color-key == Grey-300 {
    $Grey-300: $color-var;
    $default_stroke: $color-var;
  }
  @if $color-key == Grey-400 {
    $Grey-400: $color-var;
  }
  @if $color-key == Grey-800 {
    $default_onBackground: $color-var;
    $secondary_onBackground: $color-var;
    $elevation_onBackground: $color-var;
    $Grey-500: $color-var;
  }

  // Text
  @if $color-key == Grey-900 {
    $textPrimary: $color-var;
    .textPrimary {
      color: $color-var;
    }
  }
  @if $color-key == Grey-500 {
    $textSecondary: $color-var;
    .textSecondary {
      color: $color-var;
    }
  }
  @if $color-key == Grey-0 {
    .textContrast {
      color: $color-var;
    }
  }
  @if $color-key == Grey-400 {
    $textDisabled: $color-var;
    .textDisabled {
      color: $color-var;
    }
  }

  // Border
  @if $color-key == Grey-200 {
    $borderLight: $color-var;
    .borderLight {
      background-color: $color-var;
    }
  }
  @if $color-key == Grey-300 {
    $borderDark: $color-var;
    .borderDark {
      background-color: $color-var;
    }
  }

  // States
  @if $color-key == Grey-200 {
    $neutralDefault: $color-var;
    $disabledDefault: $color-var;
    .neutralDefault {
      background-color: $color-var;
    }
    .disabledDefault {
      background-color: $color-var;
    }
  }
  @if $color-key == Grey-300 {
    $neutralHover: $color-var;
    .neutralHover {
      background-color: $color-var;
    }
  }
  @if $color-key == Grey-400 {
    $neutralFocused: $color-var;
    .neutralFocused {
      background-color: $color-var;
    }
  }
}

$Brand-Medium: none;
$Brand-Dark: none;
$Brand-Darker: none;
$Brand-Light: none;
$Brand-Lighter: none;
$Brand-Lightest: none;

$brand: (
  Darker: #3843ff,
  Dark: #4958ff,
  Medium: #5667ff,
  Light: #ccd1ff,
  Lighter: #e1e4ff,
  Lightest: #eeefff,
);
@each $color-key, $color-var in $brand {
  .brand--#{$color-key} {
    background-color: $color-var;
  }

  // OLD Default variables. Will be depricated;
  @if $color-key == Darker {
    $Brand-Darker: $color-var;
    $primary: $color-var;
    $primary_background-hover: $color-var;
  }
  @if $color-key == Dark {
    $Brand-Dark: $color-var;
    $primary_text: $color-var;
    $primary_stroke: $color-var;
  }
  @if $color-key == Medium {
    $Brand-Medium: $color-var;
    $primary_background: $color-var;
    $secondary_background-hover: $color-var;
  }
  @if $color-key == Light {
    $Brand-Light: $color-var;
  }
  @if $color-key == Lighter {
    $Brand-Lighter: $color-var;
  }
  @if $color-key == Lightest {
    $Brand-Lightest: $color-var;
    $secondary_background: $color-var;
    .textLightest {
      color: $color-var;
    }
  }

  // Text
  @if $color-key == Darker {
    $textLink: $color-var;
    .textLink {
      color: $color-var;
    }
  }

  // Border
  @if $color-key == Medium {
    .borderActive {
      background-color: $color-var;
    }
  }

  // States
  @if $color-key == Darker {
    .primaryFocused {
      background-color: $color-var;
    }
  }
  @if $color-key == Dark {
    .primaryHover {
      background-color: $color-var;
    }
  }
  @if $color-key == Medium {
    .primaryDefault {
      background-color: $color-var;
    }
  }
  @if $color-key == Light {
    .secondaryFocused {
      background-color: $color-var;
    }
  }
  @if $color-key == Lighter {
    .secondaryHover {
      background-color: $color-var;
    }
  }
  @if $color-key == Lightest {
    .secondaryDefault {
      background-color: $color-var;
    }
  }
}

//-------------------------

// Shadows Colours ----------------------
$elevation1: 0px 0px 6px 0px
  rgba(
    $color: $borderDark,
    $alpha: 0.5,
  );

$elevation2: 0px 0px 8px 1px
    rgba(
      $color: $borderDark,
      $alpha: 0.5,
    ),
  inset 0px 0px 0px 1px
    rgba(
      $color: $borderLight,
      $alpha: 1,
    );
//-------------------------
// Functional Colours ----------------------
$urgent: (
  Dark: #844002,
  Medium: #ef9644,
  Light: #ffc28a,
  Lighter: #ffead6,
);
@each $color-key, $color-var in $urgent {
  .urgent--#{$color-key} {
    background-color: $color-var;
  }
  .urgentText--#{$color-key} {
    color: $color-var;
  }
  // OLD Default variables. Will be depricated;
  @if $color-key == Dark {
    $error_onBackground: $color-var;
  }
  @if $color-key == Medium {
    $error_text: $color-var;
    $error_shape: $color-var;
  }
  @if $color-key == Light {
    $error_background: $color-var;
  }
}
$danger: (
  Dark: #832e1b,
  Medium: #ef6344,
  Light: #f2c7b5,
  Lighter: #fae9e5,
);
@each $color-key, $color-var in $danger {
  .danger--#{$color-key} {
    background-color: $color-var;
  }
  .dangerText--#{$color-key} {
    color: $color-var;
  }
  // OLD Default variables. Will be depricated;
  @if $color-key == Dark {
    $error_onBackground: $color-var;
  }
  @if $color-key == Medium {
    $error_text: $color-var;
    $error_shape: $color-var;
  }
  @if $color-key == Light {
    $error_background: $color-var;
  }
}
$warning_dark: none;
$warning_medium: none;
$warning_light: none;
$warning_lighter: none;
$warning: (
  Dark: #7f5a11,
  Medium: #edd34a,
  Light: #faf3bb,
  Lighter: #fffde4,
);
@each $color-key, $color-var in $warning {
  .warning--#{$color-key} {
    background-color: $color-var;
  }
  .warningText--#{$color-key} {
    color: $color-var;
  }
  @if $color-key == Light {
    $warning_light: $color-var;
  }
  @if $color-key == Lighter {
    $warning_lighter: $color-var;
  }
  @if $color-key == Medium {
    $warning_medium: $color-var;
  }
  @if $color-key == Dark {
    $warning_dark: $color-var;
  }
}

$success_dark: none;
$success_medium: none;
$success_light: none;
$success_lighter: none;
$success: (
  Dark: #215232,
  Medium: #42bd56,
  Light: #bde7b3,
  Lighter: #e2f4de,
);
@each $color-key, $color-var in $success {
  .success--#{$color-key} {
    background-color: $color-var;
  }
  .successText--#{$color-key} {
    color: $color-var;
  }

  // OLD Default variables. Will be depricated;
  @if $color-key == Dark {
    $success_onBackground: $color-var;
    $success_dark: $color-var;
  }
  @if $color-key == Medium {
    $success_text: $color-var;
    $success_medium: $color-var;
  }
  @if $color-key == Light {
    $success_shape: $color-var;
    $success_light: $color-var;
  }
  @if $color-key == Lighter {
    $success_background: $color-var;
    $success_lighter: $color-var;
  }
}

$infoMedium: none;
$info: (
  Dark: #144e70,
  Medium: #69bde2,
  Light: #b7dbed,
  Lighter: #e3f3fd,
);
@each $color-key, $color-var in $info {
  .info--#{$color-key} {
    background-color: $color-var;
  }
  .infoText--#{$color-key} {
    color: $color-var;
  }
  @if $color-key == Medium {
    $infoMedium: $color-var;
  }
  @if $color-key == Dark {
    .textInfoDark {
      color: $color-var;
    }
  }
}

$surface: (
  3: #f3f4f6,
  2: #fcfcfc,
  1: #fff,
);
@each $color-key, $color-var in $surface {
  .surface--#{$color-key} {
    background-color: $color-var;
  }
}
$highlightMedium: none;
$highlightDark: none;
$highlightLight: none;
$highlightLighter: none;

$highlight: (
  Dark: #561068,
  Medium: #d372eb,
  Light: #ebb2f9,
  Lighter: #f1d8f7,
);
@each $color-key, $color-var in $highlight {
  .highlight--#{$color-key} {
    background-color: $color-var;
  }
  .highlightText--#{$color-key} {
    color: $color-var;
  }
  @if $color-key == Lighter {
    $highlightLighter: $color-var;
  }
  @if $color-key == Light {
    $highlightLight: $color-var;
  }
  @if $color-key == Medium {
    $highlightMedium: $color-var;
    .textHighlightMedium {
      color: $color-var;
    }
  }
  @if $color-key == Dark {
    $highlightDark: $color-var;
    .textHighlightDark {
      color: $color-var;
    }
  }
}

//-------------------------
