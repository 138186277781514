@import "styles-colors.scss";
@import "device-sizes.scss";
@import "styles-typography.scss";
@import "https://cdn.jsdelivr.net/npm/@tabler/core@1.0.0-beta17/dist/css/tabler-flags.min.css";
@import url("https://fonts.googleapis.com/css2?family=Chivo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

i-tabler {
  stroke-width: 1.2px !important;

  display: flex;
  &.xLarge {
    width: 40px;
    height: 40px;
  }
  &.large {
    width: 24px;
    height: 24px;
  }
  &.medium {
    width: 20px;
    height: 20px;
  }
  &.small {
    width: 16px;
    height: 16px;
  }
  &.xs {
    width: 12px;
    height: 12px;
  }
}

body,
textarea {
  font-family: $general-sans-regular;
  font-size: 1rem;
  margin: 0;

  input {
    font-size: 100%;
  }
}
//wrap step selection
.wrap-selection {
  display: inline-flex;
  flex-direction: column;
  gap: 64px;
  min-width: 444px;
  width: 100%;
  @media ($phone) {
    min-width: unset;
  }
  .label2 {
    display: flex;
    align-items: center;
    > span {
      margin-left: 8px;
      text-transform: capitalize;
      display: flex;
      padding: 4px 10px;
      background-color: rgba($color: $elevation_primary, $alpha: 0.1);
      border-radius: 12px;
    }
  }
}

//Input
.field,
.box,
.select,
.list {
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 100%;
  &.disabled {
    color: rgba($color: $default_onBackground, $alpha: 0.4);
    label {
      color: rgba($color: $default_onBackground, $alpha: 0.4);
    }
    input {
      pointer-events: none;
    }
    .content-field {
      background-color: rgba($color: $elevation_default, $alpha: 1);
    }
  }
  ::placeholder {
    color: rgba($color: $default_onBackground, $alpha: 0.4);
  }
  label {
    color: $default_onBackground;
    &.disabled {
      color: rgba($color: $default_onBackground, $alpha: 0.4);
    }
  }
  .content-field {
    width: 100%;
    height: 100%;
    border: 1px solid rgba($color: $default_stroke, $alpha: 1);
    padding: 8px;
    border-radius: 4px;
    overflow: auto;
    > div {
      height: 100%;
      width: 100%;
    }
  }
  > div {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    > i-tabler {
      position: absolute;
      right: 8px;
    }
    &.leadingIcon {
      color: $textSecondary;
      > i-tabler {
        left: 16px;
      }
      input,
      .input {
        color: $textSecondary;
        padding-left: 48px;
      }
    }
  }
  &.combined {
    > div {
      > i-tabler {
        border-radius: 4px;
        box-shadow: 0 0 0 1px inset $default_stroke;
        position: unset;
        padding: 8px 10px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        margin-right: -1px;
        display: flex;
      }
    }
  }
  .input {
    text-overflow: ellipsis;
    outline: unset;
    border: 0;
    border-radius: 4px;
    box-shadow: 0 0 0 1px inset $default_stroke;
    justify-content: center;
    padding: 8px;
    line-height: 150%;
    align-items: center;
    width: 100%;
    &.trailingIcon {
      padding-right: 40px;
    }
    // min-height: 40px;
    &:focus,
    &.active {
      box-shadow: 0 0 0 2px inset $primary_stroke;
    }
    &:hover {
      &:not(:disabled) {
        // background-color: $elevation_primary;
      }
    }
    &:disabled {
      color: rgba($color: $default_onBackground, $alpha: 0.4);
    }
  }
}
.box {
  flex-direction: row;
  gap: 40px;
  input {
    text-align: center;
    border-radius: 8px;
    width: 100px;
    height: 152px;
  }
}
.combined {
  > div {
    display: flex;
  }

  &.leading {
    .input {
      margin-right: -1px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    input {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  &.trailing {
    .input {
      margin-left: -1px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.select {
  display: flex;
  flex-direction: column;
  .wrap-select {
    position: relative;
    display: flex;
    align-items: center;
    i-tabler {
      right: 8px;
      position: absolute;
    }
  }
  .select-dropdown {
    position: relative;
    > div {
      display: flex;
      flex-direction: column;
      position: absolute;
      max-height: 344px;
      overflow: auto;
      box-shadow: 0px 8px 24px rgb(34 34 34 / 15%);
      background-color: white;
      z-index: 1;
      -webkit-appearance: none;
      outline: none;
      min-width: 150px;
      // position: relative;
      .split {
        border-top: 1px solid #e3e2e1;
        position: absolute;
        width: 100%;
        left: 0;
        margin-top: -20px;
      }
      > div {
        padding: 18px;
        &.selected {
          background-color: $primary_background;
          color: white;
        }
        &:hover {
          cursor: pointer;
          background-color: $primary_background-hover;
          color: white;
        }
        i {
          opacity: 0.5;
          font-size: 0.8rem;
        }
      }
    }
  }
}
.steps {
  flex-direction: column;
  display: inline-flex;
  gap: 4px;
  width: 100%;
  .bar {
    height: 6px;
    border-radius: 4px;
    background-color: $secondary_background;
    align-self: stretch;
    &.active {
      background-color: $default_onBackground;
    }
  }
  > label {
    text-transform: uppercase;
  }
}
.progress-bar {
  display: flex;
  flex-direction: column;
  gap: 16px;
  > div {
    display: flex;
    gap: 4px;
    app-steps {
      width: 100%;
    }
  }
}

.slider {
  width: 100%;
  max-width: 440px;
  padding: 16px 12px;
  background-color: rgba($color: $elevation_default, $alpha: 1);
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  gap: 40px;
  border-radius: 8px;
  > div {
    display: flex;
    flex-direction: column;
    > div {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .caption {
        opacity: 0.4;
        text-transform: uppercase;
      }
    }
  }
  .mat-slider {
    width: 100%;
    &.default-slider {
      .mat-slider-thumb {
        transform: scale(1);
        background-color: $primary_background !important;
        border-color: $primary_background !important;
        height: 24px;
        width: 24px;
        right: -12px;
        bottom: -13px;
      }
      .mat-slider-wrapper {
        left: 0;
        right: 0;
      }
      .mat-slider-track-wrapper {
        background-color: unset;
        height: 5px;
        border-radius: 5px;
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        &::before,
        &::after {
          content: "";
          background-color: rgba($color: $primary_background, $alpha: 0.4);
          height: 5px;
          width: 5px;
          position: absolute;
          z-index: 1;
          border-radius: 5px;
        }
        &::after {
          left: unset;
          right: 0;
        }
        div:nth-child(1) {
          &::before {
            content: "";
            background-color: rgba($color: $primary_background, $alpha: 0.4);
            height: 5px;
            width: 5px;
            position: absolute;
            z-index: 1;
            border-radius: 5px;
            left: calc(50% - 2px);
          }
        }
        > div {
          display: flex;
          align-items: center;
          transform: unset !important;
          height: 5px;
          background-color: $secondary_background;
        }
      }
    }
  }
}
.widgets {
  .agent,
  .profile-strength {
    display: flex;
    align-items: center;
    gap: 16px;
    text-align: right;
    > div {
      max-width: 172px;
      color: rgba($color: $default_onBackground, $alpha: 1);
      .caption {
        opacity: 0.4;
        margin-bottom: -1px;
      }
      .body2 {
        color: $primary_text;
      }

      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
    img {
      border-radius: 100%;
      height: 58px;
      width: 58px;
    }
    .circle {
      height: 74px;
      width: 74px;
      circle-progress {
        height: 74px;
        width: 74px;
        display: block;
        tspan {
          fill: rgba($color: $default_onBackground, $alpha: 1);
          font-size: 3rem;
          font-weight: $label1-font-weight;
        }
        path {
          stroke: rgba($color: $default_onBackground, $alpha: 1);
          stroke-width: 12px;
        }
        circle {
          stroke: rgba($color: $secondary_background, $alpha: 1);
        }
      }
    }
  }
}
.select-items {
  position: relative;
  display: flex;
  min-height: 24px;
  width: fit-content;
  align-items: center;
  cursor: pointer;
  > div {
    display: flex;
  }
  .mat-slide-toggle {
    .mat-slide-toggle-bar {
      height: 24px;
      width: 44px;
      display: flex;
      align-items: center;
      border-radius: 24px;
      background-color: rgba($color: $default_stroke, $alpha: 1);
      .mat-slide-toggle-thumb-container {
        position: unset;
        height: 18px;
        width: 18px;
        margin-left: 3px;
        .mat-slide-toggle-thumb {
          height: 18px;
          width: 18px;
        }
        .mat-ripple {
          display: none;
        }
      }
    }
    &.mat-checked {
      .mat-slide-toggle-bar {
        background-color: rgba($color: $primary_background, $alpha: 1);
      }
      .mat-slide-toggle-thumb {
        background-color: $default_background;
        box-shadow: 0;
      }
      .mat-slide-toggle-thumb-container {
        transform: translate3d(20px, 0, 0);
      }
    }
  }
}
.buttons {
  cursor: pointer;
  display: inline-flex;
  padding: 12px;
  // gap: 8px;
  background-color: rgba($color: $elevation_default, $alpha: 1);
  border-radius: 4px;
  align-items: center;
  // min-height: 55px;
  &:hover {
    background-color: rgba($color: $elevation_primary, $alpha: 0.1);
  }
  &.active {
    background-color: $secondary_background;
    &:hover {
      background-color: $secondary_background-hover;
    }
  }
  &.description {
    min-width: 180px;
    align-items: flex-start;
    width: 100%;
  }
  > div {
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 2px 0;
  }

  .checkmark {
    pointer-events: none;
  }
}
